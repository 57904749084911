import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationData } from './auth/user.model';
import { catchError } from 'rxjs/internal/operators/catchError';
import { AuthService } from './auth/auth.service';
import { UIService } from 'src/app/shared/ui.service';
import { environment } from 'src/environments/environment';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private uiService: UIService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser: ApplicationData = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token && !request.url.startsWith('https://nominatim') && !request.url.startsWith(environment.auditUrl)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }
    else if (currentUser && currentUser.token && request.url.startsWith(environment.auditUrl)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
          'x-api-key': '53uP8f7DLFCjCQ0N'
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err, caught: Observable<HttpEvent<any>>) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this.auth.logout();
        } else if (err instanceof HttpErrorResponse && err.status === 471) {
          if (err.error.messages !== undefined) {
            err.error.messages.map((err) => {
              this.uiService.openSnack(err, 'Błąd danych', 10_000, true);
            });
          }
        }
        throw err;
      })
    );
  }
}
