import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MessageModel } from '../message-dialog/message-dialog.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-start-end-date-picker-dialog',
  templateUrl: './start-end-date-picker-dialog.component.html',
  styleUrls: ['./start-end-date-picker-dialog.component.css']
})
export class StartEndDatePickerDialogComponent implements OnInit {
  model: MessageModel;
  startDate: Date = null;
  endDate: Date = null;
  datesCorrect: boolean = false;

  fg: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<StartEndDatePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MessageModel
  ) {
    this.model = data;
  }

  ngOnInit() {
    this.fg = this.fb.group({
      formStartDate: new FormControl('', Validators.required),
      formEndDate: new FormControl('', Validators.required),
    });
  }

  submit() {
    const result = {
      startDate: this.startDate,
      endDate: this.endDate,
    };
    this.dialogRef.close(result);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  onStartDateChange(date: Date) {
    this.startDate = date;
  }

  onEndDateChange(date: Date) {
    this.endDate = date;
  }

  onDateCorrectChange(value: boolean) {
    this.datesCorrect = value;
  }
}
