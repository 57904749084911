import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import * as OrderTaskActions from 'src/app/redux/order-task/order-task.actions';
import * as SurveyActions from 'src/app/redux/survey/survey.actions';
import { environment } from 'src/environments/environment';
import { saveAs as importedSaveAs } from 'file-saver';
import { OrderTask, TaskPhoto, ProductBase, TasksGallery, OrderTaskParameters, SurveyData, OrderTaskDxTable, OfficeApprovalConfirmation } from 'src/app/redux/order-task/order-task.model';
import { SurveyPattern } from 'src/app/redux/survey/survey.model';
import { takeUntil } from 'rxjs/operators';
import * as ParamsAndToolsActions from 'src/app/redux/params-and-tools/params-and-tools.actions';
import * as ExcelExportActions from 'src/app/redux/excel-export/excel-export.actions';
import { QueueSendResult } from 'src/app/redux/shared/shared.model';

@Injectable()
export class OrderTaskService {
  private _onDestroy = new Subject<void>();

  constructor(
    private store: Store<root.IRootState>,
    private http: HttpClient,
    private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
    this._onDestroy.next();
    this._onDestroy.complete();

    this.unSelectTask();
  }

  stopEditing() {
    this.store.dispatch(new OrderTaskActions.StopEditing());
  }

  startEditing() {
    this.store.dispatch(new OrderTaskActions.StartEditing());
  }

  stopSurveyPatternEditing() {
    this.store.dispatch(new SurveyActions.StopEditing());
  }

  startSurveyPatternEditing() {
    this.store.dispatch(new SurveyActions.StartEditing());
  }

  selectTask(task: OrderTask) {
    this.store.dispatch(new OrderTaskActions.SetSelectedTask(task));
  }

  unSelectTask() {
    this.store.dispatch(new OrderTaskActions.SetSelectedTask(null));
    this.store.dispatch(new OrderTaskActions.SetSurveyData(null));
  }

  selectSurveyPattern(pattern: SurveyPattern) {
    this.store.dispatch(new SurveyActions.SetSelectedSurveyPattern(pattern));
  }

  unSelectSurveyPattern() {
    this.store.dispatch(new SurveyActions.SetSelectedSurveyPattern(null));
  }

  clearTaskProducts() {
    this.store.dispatch(new OrderTaskActions.SetAvailableTaskProducts([]));
  }

  clearTaskPhotos() {
    this.store.dispatch(new OrderTaskActions.SetAvailableTaskPhotos([]));
  }

  clearTaskGallery() {
    this.store.dispatch(new OrderTaskActions.SetAvailableTasksGallery([]));
  }

  selectTasksForSurveyResults(taskIds: number[]) {
    this.store.dispatch(new SurveyActions.SetAvailableOrderTasksForResults(taskIds));
  }

  clearTasksForSurveyResults() {
    this.store.dispatch(new SurveyActions.SetAvailableOrderTasksForResults([]));
  }

  getTasks() {
    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.get<OrderTask[]>(environment.apiUrl + '/ordertask')
      .toPromise()
      .then(
        (tasks) => {
          this.store.dispatch(new OrderTaskActions.SetAvailableTasks(tasks));
          this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.SetAvailableTasks(null));
        this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  getMyTasks() {
    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.get<OrderTask[]>(environment.apiUrl + '/ordertask/mytasks')
      .toPromise()
      .then(
        (tasks) => {
          this.store.dispatch(new OrderTaskActions.SetMyTasks(tasks));
          this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.SetMyTasks(null));
        this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  getTasksByPeriod(start: number, finish: number) {
    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.get<OrderTask[]>(environment.apiUrl + '/ordertask/' + start + '/' + finish)
      .toPromise()
      .then(
        (tasks) => {
          this.store.dispatch(new OrderTaskActions.SetAvailableTasks(tasks));
          this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.SetAvailableTasks(null));
        this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  refreshTempContainer(orderTaskId: number) {
    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.get<OrderTask>(environment.apiUrl + '/ordertask/refresh-tempcontainer/' + orderTaskId)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        res => {
          this.uiService.openSnack('Stan zadania został odświeżony: Id: ' + orderTaskId);
          this.store.dispatch(new OrderTaskActions.StopLoading());
          this.store.dispatch(new OrderTaskActions.SetTempContainerRefreshed(true));
          this.store.dispatch(new OrderTaskActions.SetSelectedTask(res));

        }, err => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          this.store.dispatch(new OrderTaskActions.StopLoading());
          this.store.dispatch(new OrderTaskActions.SetTempContainerRefreshed(false));

          console.error(err);
        }
      );
  }

  resetError() {
    this.store.dispatch(new OrderTaskActions.SetError(false));
  }

  resetTempContainerRefreshed() {
    this.store.dispatch(new OrderTaskActions.SetTempContainerRefreshed(false));
  }

  getTaskById(id: number) {
    this.resetError();
    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.get<OrderTask>(environment.apiUrl + '/ordertask/' + id)
      .toPromise()
      .then(
        (task) => {
          this.store.dispatch(new OrderTaskActions.SetSelectedTask(task));
          this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd podczas pobierania zadania', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.SetSelectedTask(null));
        this.store.dispatch(new OrderTaskActions.SetError(true));
        this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  getSurveyDataByOrderId(id: number) {
    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.get<SurveyData>(environment.apiUrl + '/orderTask/surveyData/' + id)
      .toPromise()
      .then(
        (res) => {
          this.store.dispatch(new OrderTaskActions.SetSurveyData(res));
          this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.SetSurveyData(null));
        this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  createTask(model) {
    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.post<OrderTask>(environment.apiUrl + '/ordertask', model)
      .toPromise()
      .then(
        (model) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  generateTasks(model) {
    this.uiService.openSnack('Trwa generowanie zadań...', null, null);
    this.http.post<number[]>(environment.apiUrl + '/ordertask/generatetasks', model)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
      });
  }

  reloadData() {
    this.store.dispatch(new OrderTaskActions.SetReloadData(true));
  }

  unsetReloadData() {
    this.store.dispatch(new OrderTaskActions.SetReloadData(false));
  }

  updateTask(model, fromList: boolean = false) {
    var apiUrl = environment.apiUrl + (fromList ? '/ordertask/list' : '/ordertask');

    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.put<OrderTask>(apiUrl, model)
      .toPromise()
      .then(
        (updatedModel) => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.selectTask(updatedModel);
          this.store.dispatch(new OrderTaskActions.StopLoading());
          if (fromList) {
            this.reloadData();
          }
        })
      .catch(err => {
        if (err && err.error && err.error.Message) {
          this.uiService.openSnack('Błąd: ' + err.error.Message, null, 3000);
        }
        else {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        }
        this.store.dispatch(new OrderTaskActions.StopLoading());
        this.unsetReloadData();
        console.error(err);
      });
  }

  updatePhotoCreationTime(taskId: number, fileCreatedTime: number, fileName: string) {
    this.http.put<OrderTask>(environment.apiUrl + '/ordertask/realization/photo/updatecreationtime',
      {
        taskId,
        fileCreatedTime,
        fileName
      })
      .toPromise()
      .then(
        () => { })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd podczas aktualizacji daty utworzenia zdjęcia.', 'Błąd', 10_000);
        console.error(err);
      });
  }

  cancelTasks(tasksIds: number[]) {
    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.put(environment.apiUrl + '/ordertask/cancel', tasksIds)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  unsetOfficeApprovalConfirmation() {
    this.store.dispatch(new OrderTaskActions.SetOfficeApprovalConfirmation(null));
  }

  acceptSelectedTask(tasksIds: number[]) {
    const body = {
      OrderTaskIds: tasksIds,
    };

    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.put<QueueSendResult>(environment.apiUrl + '/order-tasks-office-approval/v1/accept', body)
      .toPromise()
      .then(
        (res) => {
          this.store.dispatch(new ExcelExportActions.SetMessage(res.message));
          this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  getTaskIdsForAcceptSelection(loadOptions: any, startDate: Date, endDate: Date, projectKindId, projectId: number, marketId: number) {
    if (!startDate || !endDate || projectKindId === -1) {
      return;
    }
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    if (startTime < 0 || endTime < 0 || Number.isNaN(startTime) || Number.isNaN(endTime)) {
      return;
    }

    if (projectKindId == 0) {
      return;
    }

    let params: HttpParams = new HttpParams();
    [
      'skip',
      'take',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
      'projectId',
      'marketId'
    ].forEach((i) => {
      if (i in loadOptions && this.isNotEmpty(loadOptions[i])) {
        params = params.set(i, JSON.stringify(loadOptions[i]));
      }
    });

    params = params.set('requireTotalCount', "true");
    params = params.set('projectId', projectId.toString());
    params = params.set('marketId', marketId.toString());

    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.get<OfficeApprovalConfirmation>(environment.apiUrl +
      '/order-tasks-office-approval/v1/list/'
      + startTime + '/'
      + endTime + '/projectKind/'
      + projectKindId, { params })
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.store.dispatch(new OrderTaskActions.SetOfficeApprovalConfirmation(response));
          this.store.dispatch(new OrderTaskActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new OrderTaskActions.StopLoading());
        }
      );
  }

  deleteTasks(tasksIds: number[]) {
    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.put(environment.apiUrl + '/ordertask/delete', tasksIds)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  downloadPhoto(attachmentId, fileName, filetype) {
    this.http.get(environment.apiUrl + '/ordertask/realization/photo/download/' + attachmentId, {
      responseType: 'arraybuffer'
    })
      .toPromise()
      .then((res) => {
        const blob = new Blob([res], { type: filetype });
        importedSaveAs(blob, fileName);
      })
      .catch(err => {
        console.error(err);
      });
  }

  downloadGallery(taskIds: number[], fileName, filetype) {
    this.http.put(environment.apiUrl + '/ordertask/realization/photo/download/zip', taskIds, {
      responseType: 'arraybuffer'
    })
      .toPromise()
      .then((res) => {
        const blob = new Blob([res], { type: filetype });
        importedSaveAs(blob, fileName);
      })
      .catch(err => {
        console.error(err);
      });
  }

  removePhoto(attachmentId: number) {
    //this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.delete(environment.apiUrl + '/ordertask/realization/photo/' + attachmentId)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          //this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        //this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  getTaskPhotos(taskId: number) {
    this.http.get<TaskPhoto[]>(environment.apiUrl + '/orderTask/realization/photo/' + taskId)
      .toPromise()
      .then(
        (photos) => {
          this.store.dispatch(new OrderTaskActions.SetAvailableTaskPhotos(photos));
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.SetAvailableTaskPhotos(null));
        console.error(err);
      });
  }

  getTasksGallery(tasksIds: number[]) {
    this.store.dispatch(new OrderTaskActions.StartGalleryLoading());
    this.http.put<TasksGallery[]>(environment.apiUrl + '/orderTask/realization/gallery/', tasksIds)
      .toPromise()
      .then(
        (photos) => {
          this.store.dispatch(new OrderTaskActions.SetAvailableTasksGallery(photos));
          this.store.dispatch(new OrderTaskActions.StopGalleryLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.SetAvailableTasksGallery(null));
        this.store.dispatch(new OrderTaskActions.StopGalleryLoading());
        console.error(err);
      });
  }

  getTaskProducts(taskId: number) {
    this.http.get<ProductBase[]>(environment.apiUrl + '/orderTask/realization/product/' + taskId)
      .toPromise()
      .then(
        (products) => {
          this.store.dispatch(new OrderTaskActions.SetAvailableTaskProducts(products));
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.SetAvailableTaskProducts(null));
        console.error(err);
      });
  }

  addProductsToReport(products: number[], taskId: number) {
    //this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.post(environment.apiUrl + '/orderTask/realization/reportoos/products', { missingProductIds: products, taskId })
      .toPromise()
      .then(
        (model) => {
          this.getTaskProducts(taskId);
          this.uiService.openSnack('Zakończono pomyślnie');
          //this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        //this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  removeProductsFromReport(products: number[], taskId: number) {
    //this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.put(environment.apiUrl + '/orderTask/realization/reportoos/products', { missingProductIds: products, taskId })
      .toPromise()
      .then(
        (model) => {
          this.getTaskProducts(taskId);
          this.uiService.openSnack('Zakończono pomyślnie');
          //this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        //this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  setReportStatus(status: number, taskId: number, realizationNote: string) {
    this.http.put(environment.apiUrl + '/orderTask/realization/status', { status, taskId, realizationNote })
      .toPromise()
      .then(
        (model) => {
          this.uiService.openSnack('Zakończono pomyślnie');
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
      });
  }

  updateSelectedTasks(orderParameters: OrderTaskParameters) {
    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.put(environment.apiUrl + '/ordertask/updateselected', orderParameters)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new OrderTaskActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd w aktualizacji zadań', 'Błąd', 10_000);
        this.store.dispatch(new OrderTaskActions.StopLoading());
        console.error(err);
      });
  }

  getSurveyPatterns() {
    this.http.get<SurveyPattern[]>(environment.apiUrl + '/orderTask/surveypatternforweb')
      .toPromise()
      .then(
        (surveyPatterns) => {
          this.store.dispatch(new SurveyActions.SetAvailableSurveyPatterns(surveyPatterns));
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new SurveyActions.SetAvailableSurveyPatterns(null));
        console.error(err);
      });
  }

  getSurveyPatternsForProject(projectId: number, withSurveyDefinition: boolean) {
    this.http.get<SurveyPattern[]>(environment.apiUrl + '/orderTask/surveypatternForProject/' + projectId + '/' + withSurveyDefinition)
      .toPromise()
      .then(
        (surveyPatterns) => {
          this.store.dispatch(new SurveyActions.SetAvailableSurveyPatternsForProject(surveyPatterns));
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new SurveyActions.SetAvailableSurveyPatternsForProject(null));
        console.error(err);
      });
  }

  createSurveyPattern(model) {
    this.store.dispatch(new SurveyActions.StartLoading());
    this.http.post<SurveyPattern>(environment.apiUrl + '/orderTask/surveypattern', model)
      .toPromise()
      .then(() => {
        this.uiService.openSnack('Zakończono pomyślnie');
        this.store.dispatch(new SurveyActions.StopLoading());
      })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new SurveyActions.StopLoading());
        console.error(err);
      });
  }

  updateSurveyPattern(model) {
    this.store.dispatch(new SurveyActions.StartLoading());
    this.http.put<SurveyPattern>(environment.apiUrl + '/orderTask/surveypattern', model)
      .toPromise()
      .then(() => {
        this.uiService.openSnack('Zakończono pomyślnie');
        this.store.dispatch(new SurveyActions.StopLoading());
      })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new SurveyActions.StopLoading());
        console.error(err);
      });
  }

  copySurveyPatterns(surveyIds) {
    this.store.dispatch(new SurveyActions.StartLoading());
    this.http.put<SurveyPattern>(environment.apiUrl + '/orderTask/surveypattern/clone', surveyIds)
      .toPromise()
      .then(() => {
        this.uiService.openSnack('Zakończono pomyślnie');
        this.store.dispatch(new SurveyActions.StopLoading());
      })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new SurveyActions.StopLoading());
        console.error(err);
      });
  }

  cancelSurveyPatterns(surveyIds) {
    this.store.dispatch(new SurveyActions.StartLoading());
    this.http.put<SurveyPattern>(environment.apiUrl + '/orderTask/surveypattern/cancel', surveyIds)
      .toPromise()
      .then(() => {
        this.uiService.openSnack('Zakończono pomyślnie');
        this.store.dispatch(new SurveyActions.StopLoading());
      })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new SurveyActions.StopLoading());
        console.error(err);
      });
  }

  deleteSurveyPattern(surveyIds) {
    this.store.dispatch(new SurveyActions.StartLoading());
    this.http.put<SurveyPattern>(environment.apiUrl + '/orderTask/surveypattern/del', surveyIds)
      .toPromise()
      .then(() => {
        this.uiService.openSnack('Zakończono pomyślnie');
        this.store.dispatch(new SurveyActions.StopLoading());
      })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new SurveyActions.StopLoading());
        console.error(err);
      });
  }

  saveSurveyResult(orderTaskId: number, data: string, isCompleteOnTrigger: boolean) {
    this.store.dispatch(new OrderTaskActions.StartLoading());
    this.http.put(environment.apiUrl + '/orderTask/surveyresult', { orderTaskId, data, isCompleteOnTrigger })
      .toPromise()
      .then(() => {
        this.store.dispatch(new OrderTaskActions.StopLoading());
        this.reloadData();
        this.uiService.openSnack('Zakończono pomyślnie');
      })
      .catch(err => {
        this.store.dispatch(new OrderTaskActions.StopLoading());
        this.unsetReloadData();
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        console.error(err);
      });
  }

  getDataSourceDefaultValue(): Promise<any> {
    const defaultValue: OrderTaskDxTable = {
      data: [],
      totalCount: 0,
      groupCount: 0,
      summary: [],
    };
    return Promise.resolve(defaultValue);
  }


  updateOrderTasksFromFile(file) {
    var formData = new FormData();
    formData.append("file", file);
    this.store.dispatch(new ParamsAndToolsActions.StartProjectKindLoading());
    this.http.post(environment.apiUrl + '/orderTask/v2/update-from-file', formData).toPromise()
      .then((result: any) => {
        this.store.dispatch(new ParamsAndToolsActions.StopProjectKindLoading());
        this.store.dispatch(new ExcelExportActions.SetMessage('Poprawnie dodano ' + result + ' zadań'));
      })
      .catch(err => {
        this.store.dispatch(new ParamsAndToolsActions.StopProjectKindLoading());
        this.store.dispatch(new ExcelExportActions.SetMessage('Błąd: ' + err.error.Message));
        console.log(err);
        return null;
      });
  }

  isNotEmpty(value: any): boolean {
    return value !== undefined && value !== null && value !== '';
  }

  loadDataV2(loadOptions: any, startDate: Date, endDate: Date, projectKindId, projectId: number, marketId: number) {
    if (!startDate || !endDate || projectKindId === -1) {
      return this.getDataSourceDefaultValue();
    }
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    if (startTime < 0 || endTime < 0 || Number.isNaN(startTime) || Number.isNaN(endTime)) {
      return this.getDataSourceDefaultValue();
    }

    let params: HttpParams = new HttpParams();
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
      'projectId',
      'marketId'
    ].forEach((i) => {
      if (i in loadOptions && this.isNotEmpty(loadOptions[i])) {
        params = params.set(i, JSON.stringify(loadOptions[i]));
      }
    });

    params = params.set('projectId', projectId.toString());
    params = params.set('marketId', marketId.toString());

    return this.http.get<any>(environment.apiUrl + '/ordertask/v2/serverside/' + startTime + '/' + endTime + '/projectKind/' + projectKindId, { params })
      .toPromise();
  }

  loadMyTasksData(loadOptions: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary'
    ].forEach((i) => {
      if (i in loadOptions && this.isNotEmpty(loadOptions[i])) {
        params = params.set(i, JSON.stringify(loadOptions[i]));
      }
    });

    return this.http.get<any>(environment.apiUrl + '/ordertask/mytasks/serverside', { params })
      .toPromise();
  }
}
