import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppEnvironmentService {
    public init() {
        return from(
            fetch('assets/app-config.json').then(function (response) {
                return response.json();
            })
        ).pipe(
            map((config) => {
                environment.apiUrl = config['apiUrl'];
                environment.auditUrl = config['auditUrl'];
                environment.hub = config['hub'];
                return config;
            })).toPromise();
    }
}
