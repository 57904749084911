import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import * as ExcelExportActions from 'src/app/redux/excel-export/excel-export.actions';
import * as LocalizationActions from 'src/app/redux/localization/localization.actions';
import * as ParamsAndToolsActions from 'src/app/redux/params-and-tools/params-and-tools.actions';
import { environment } from 'src/environments/environment';
import { Localization, Market } from 'src/app/redux/localization/localization.model';
import { saveAs as importedSaveAs } from 'file-saver';
import { markets, localizations } from 'src/app/redux/localization/localization.reducer';
import { LoadOptions } from 'devextreme/data/load_options';
import { takeUntil } from 'rxjs/operators';
import { QueueSendResult } from 'src/app/redux/shared/shared.model';

@Injectable()
export class LocalizationService {
  private _onDestroy = new Subject<void>();

  constructor(
    private store: Store<root.IRootState>,
    private http: HttpClient,
    private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
    this._onDestroy.next();
    this._onDestroy.complete();

    this.unSelectLocalization();
    this.unSelectMarket();
  }

  stopEditing() {
    this.store.dispatch(new LocalizationActions.StopEditing());
  }

  startEditing() {
    this.store.dispatch(new LocalizationActions.StartEditing());
  }

  selectLocalization(localization: Localization) {
    this.store.dispatch(new LocalizationActions.SetSelectedLocalization(localization));
  }

  unSelectLocalization() {
    this.store.dispatch(new LocalizationActions.SetSelectedLocalization(null));
  }

  selectMarket(market: Market) {
    this.store.dispatch(new LocalizationActions.SetSelectedMarket(market));
  }

  unSelectMarket() {
    this.store.dispatch(new LocalizationActions.SetSelectedMarket(null));
  }

  unSelectMarketList() {
    this.store.dispatch(new ParamsAndToolsActions.SetMarketList(null));
  }

  updateOrdersLocalizationCoordinator(localizationIds: number[]) {
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.post<QueueSendResult>(environment.apiUrl + '/localization-merch-coordinator-update/v1/orders', localizationIds)
      .toPromise()
      .then(
        (res) => {
          this.store.dispatch(new ExcelExportActions.SetMessage(res.message));
          this.store.dispatch(new LocalizationActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  updateOrderTasksLocalizationCoordinator(localizationIds: number[], start: number, finish: number) {
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.post<QueueSendResult>(environment.apiUrl + '/localization-merch-coordinator-update/v1/order-tasks/' + start + '/' + finish, localizationIds)
      .toPromise()
      .then(
        (res) => {
          this.store.dispatch(new ExcelExportActions.SetMessage(res.message));
          this.store.dispatch(new LocalizationActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  getProjectLocalizations(projectId: number): Localization[] {
    this.http.get<Localization[]>(environment.apiUrl + '/localization/localization/project/' + projectId)
      .toPromise()
      .then(
        (projLocalizations) => {
          this.store.dispatch(new LocalizationActions.SetAvailableProjectLocalizations(projLocalizations));
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.SetAvailableProjectLocalizations(null));
        console.error(err);
      });
    return null;
  }

  getMarkets() {
    this.store.dispatch(new LocalizationActions.StartLoadingMarkets());
    this.http.get<Market[]>(environment.apiUrl + '/localization/market')
      .toPromise()
      .then(
        (markets) => {
          this.store.dispatch(new LocalizationActions.SetAvailableMarkets(markets));
          this.store.dispatch(new LocalizationActions.StopLoadingMarkets());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.SetAvailableMarkets(null));
        this.store.dispatch(new LocalizationActions.StopLoadingMarkets());
        console.error(err);
      });
  }

  getMarketListForOrders(projectKind: number, current: boolean) {
    this.store.dispatch(new ParamsAndToolsActions.StartMarketLoading());
    this.http.get<Market[]>(environment.apiUrl + '/localization/v2/markets-list-for-orders?projectKindId=' + projectKind + '&current=' + current)
      .toPromise()
      .then(
        (markets) => {
          this.store.dispatch(new ParamsAndToolsActions.SetMarketList(markets));
          this.store.dispatch(new ParamsAndToolsActions.StopMarketLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ParamsAndToolsActions.SetMarketList(null));
        this.store.dispatch(new ParamsAndToolsActions.StopMarketLoading());
        console.error(err);
      });
  }

  getMarketListForOrderTasks(startTime: number, endTime: number, projectKind: number) {
    this.store.dispatch(new ParamsAndToolsActions.StartMarketLoading());
    this.http.get<Market[]>(environment.apiUrl + '/localization/v2/markets-list-for-ordertasks?start=' + startTime.toString() + '&finish=' + endTime.toString() + '&projectKindId=' + projectKind)
      .toPromise()
      .then(
        (markets) => {
          this.store.dispatch(new ParamsAndToolsActions.SetMarketList(markets));
          this.store.dispatch(new ParamsAndToolsActions.StopMarketLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ParamsAndToolsActions.SetMarketList(null));
        this.store.dispatch(new ParamsAndToolsActions.StopMarketLoading());
        console.error(err);
      });
  }

  getMarketListForServices(startTime: number, endTime: number, projectKind: number) {
    this.store.dispatch(new ParamsAndToolsActions.StartMarketLoading());
    this.http.get<Market[]>(environment.apiUrl + '/localization/v2/markets-list-for-services?start=' + startTime.toString() + '&finish=' + endTime.toString() + '&projectKindId=' + projectKind)
      .toPromise()
      .then(
        (markets) => {
          this.store.dispatch(new ParamsAndToolsActions.SetMarketList(markets));
          this.store.dispatch(new ParamsAndToolsActions.StopMarketLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ParamsAndToolsActions.SetMarketList(null));
        this.store.dispatch(new ParamsAndToolsActions.StopMarketLoading());
        console.error(err);
      });
  }

  getMarketListForWorktime(startTime: number, endTime: number) {
    this.store.dispatch(new ParamsAndToolsActions.StartMarketLoading());
    this.http.get<Market[]>(environment.apiUrl + '/localization/v2/markets-list-for-worktime?start=' + startTime.toString() + '&finish=' + endTime.toString())
      .toPromise()
      .then(
        (markets) => {
          this.store.dispatch(new ParamsAndToolsActions.SetMarketList(markets));
          this.store.dispatch(new ParamsAndToolsActions.StopMarketLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new ParamsAndToolsActions.SetMarketList(null));
        this.store.dispatch(new ParamsAndToolsActions.StopMarketLoading());
        console.error(err);
      });
  }

  unsetReloadDataGrid() {
    this.store.dispatch(new LocalizationActions.SetReloadDataGrid(false));
  }

  setReloadDataGrid() {
    this.store.dispatch(new LocalizationActions.SetReloadDataGrid(true));
  }

  setArchiveLocalizations(localizations: number[]) {
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.put<number[]>(environment.apiUrl + '/localization/localization/archive', localizations)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new LocalizationActions.StopLoading());
          this.setReloadDataGrid();
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  setArchiveMarkets(markets: Market[]) {
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.put<Market[]>(environment.apiUrl + '/localization/market/archive', markets)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new LocalizationActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  createLocalization(localization: Localization) {
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.post<Localization>(environment.apiUrl + '/localization/localization', localization)
      .toPromise()
      .then(
        (model) => {
          this.store.dispatch(new LocalizationActions.SetSelectedLocalization(model));
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new LocalizationActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  createMarket(market: Market) {
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.post<Market>(environment.apiUrl + '/localization/market', market)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new LocalizationActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  updateLocalization(localization: Localization) {
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.put<Localization>(environment.apiUrl + '/localization/localization', localization)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new LocalizationActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  updateMarket(formData) {
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.put<Market>(environment.apiUrl + '/localization/market', formData)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new LocalizationActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  deleteLocalization(id: number) {
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.delete(environment.apiUrl + '/localization/localization/' + id)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new LocalizationActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  deleteMarket(id: number) {
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.delete(environment.apiUrl + '/localization/market/' + id)
      .toPromise()
      .then(
        () => {
          this.store.dispatch(new LocalizationActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  downloadLocalizationAttachment(attachment, fileName) {
    this.http.get(environment.apiUrl + '/localization/localization/attachment/' + attachment.id, {
      responseType: 'arraybuffer'
    })
      .toPromise()
      .then((res) => {
        const blob = new Blob([res], { type: attachment.type });
        importedSaveAs(blob, fileName);
      })
      .catch(err => {
        console.error(err);
      });
  }

  downloadMarketAttachment(attachment, fileName) {
    this.http.get(environment.apiUrl + '/localization/market/attachment/' + attachment.id, {
      responseType: 'arraybuffer'
    })
      .toPromise()
      .then((res) => {
        const blob = new Blob([res], { type: attachment.type });
        importedSaveAs(blob, fileName);
      })
      .catch(err => {
        console.error(err);
      });
  }

  removeLocalizationAttachment(attachmentId: number) {
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.delete(environment.apiUrl + '/localization/localization/attachment/' + attachmentId)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new LocalizationActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  removeMarketAttachment(attachmentId: number) {
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.delete(environment.apiUrl + '/localization/market/attachment/' + attachmentId)
      .toPromise()
      .then(
        () => {
          this.uiService.openSnack('Zakończono pomyślnie');
          this.store.dispatch(new LocalizationActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  getAllLocalizations() {
    this.http.get<any>(environment.apiUrl + '/localization/v2/all-localizations')
      .toPromise()
      .then(
        (res) => {
          this.store.dispatch(new LocalizationActions.SetAvailableLocalizations(res));
          this.store.dispatch(new LocalizationActions.StopLoading());
        })
      .catch(err => {
        this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
        this.store.dispatch(new LocalizationActions.StopLoading());
        console.error(err);
      });
  }

  getLocalizationById(id: number) {
    this.http.get<any>(environment.apiUrl + '/localization/v2/localization/' + id)
      .toPromise()
      .then(
        (res) => {
          this.selectLocalization(res);
        })
      .catch(err => {
        console.error(err);
      });
  }

  searchLocalization(searchQuery: string) {
    if (searchQuery === null || searchQuery.length < 4) {
      return;
    }
    this.store.dispatch(new LocalizationActions.StartLoading());
    this.http.get<Localization[]>(environment.apiUrl + '/localization/v2/search-localization?searchQuery=' + searchQuery)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        res => {
          this.store.dispatch(new LocalizationActions.StopLoading());
          this.store.dispatch(new LocalizationActions.SetSearchLocalizationList(res));

        }, err => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          this.store.dispatch(new LocalizationActions.StopLoading());
          this.store.dispatch(new LocalizationActions.SetSearchLocalizationList(null));
          console.error(err);
        }
      );
  }

  getDataSourceDefaultValue(): Promise<any> {
    const defaultValue = {
      data: [],
      totalCount: 0,
      groupCount: 0,
      summary: [],
    };
    return Promise.resolve(defaultValue);
  }

  loadDataV2(loadOptions: LoadOptions, current: boolean) {
    if (loadOptions.skip === 0 && loadOptions.take === 0) {
      return this.getDataSourceDefaultValue();
    }
    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== '';
    }

    let params: HttpParams = new HttpParams();
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary'
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params = params.set(i, JSON.stringify(loadOptions[i]));
      }
    });
    params = params.set('current', current.toString());

    return this.http.get<any>(environment.apiUrl + '/localization/v2/localization', { params })
      .toPromise();
  }
}
