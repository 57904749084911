import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import { environment } from 'src/environments/environment';
import { LoadOptions } from 'devextreme/data/load_options';
import { Audit, AuditDxTable, AuditObjectType } from 'src/app/redux/audit/audit.model';
import * as AuditActions from 'src/app/redux/audit/audit.actions';

import { takeUntil } from 'rxjs/operators';

@Injectable()
export class AuditService {
  private _onDestroy = new Subject<void>();
  constructor(
    private store: Store<root.IRootState>,
    private http: HttpClient,
    private uiService: UIService,
  ) { }

  getAuditObjectTypes() {
    this.store.dispatch(new AuditActions.StartLoading());
    this.http.get<AuditObjectType[]>(environment.auditUrl + '/audit/v1/object-types')
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (res) => {
          this.store.dispatch(new AuditActions.StopLoading());
          this.store.dispatch(new AuditActions.SetObjectTypes(res));

        }, (error) => {
          this.store.dispatch(new AuditActions.StopLoading());
          this.store.dispatch(new AuditActions.SetObjectTypes(null));
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
        }
      );
  }

  getDataSourceDefaultValue(): Promise<any> {
    const defaultValue: AuditDxTable = {
      data: [],
      totalCount: 0,
      groupCount: 0,
      summary: [],
    };
    return Promise.resolve(defaultValue);
  }

  getAuditById(id: string, objectType: string, month: string, year: string) {
    let params = new HttpParams()
    .set('objectType', objectType)
    .set('year', year)
    .set('month', month);
    this.store.dispatch(new AuditActions.StartLoading());
    this.http.get<Audit>(environment.auditUrl + '/audit/v1/entries/' + id, { params })
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (res) => {
          this.store.dispatch(new AuditActions.StopLoading());
          this.store.dispatch(new AuditActions.SetSelectedAudit(res));

        }, (error) => {
          this.store.dispatch(new AuditActions.StopLoading());
          this.store.dispatch(new AuditActions.SetSelectedAudit(null));
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
        }
      );
  }

  loadAuditsData(objectType: string, userId: string, loadOptions: any, monthDate: Date, startDate: Date = null, endDate: Date = null, primaryKey: number = 0): Promise<any> {

    if (loadOptions.take === 0) {
      return this.getDataSourceDefaultValue();
    }

    const year = monthDate.getFullYear().toString();
    const month = (monthDate.getMonth() + 1).toString();

    const page = Math.floor(loadOptions.skip / loadOptions.take) + 1;
    let params = new HttpParams()
      .set('objectType', objectType)
      .set('page', page.toString())
      .set('pageSize', loadOptions.take)
      .set('year', year)
      .set('month', month);

    if (userId != null) {
      params = params.set('userId', userId)
    }

    if (primaryKey > 0) {
      params = params.set('objectPrimaryKey', primaryKey.toString())
    }

    console.log(params);

    if (startDate && endDate) {
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();

      if (!(startTime < 0 || endTime < 0 || Number.isNaN(startTime) || Number.isNaN(endTime))) {
        params = params.set('startDate', startTime.toString())
        params = params.set('endDate', endTime.toString());
      }
    }

    return this.http.get<any>(environment.auditUrl + '/audit/v1/entries', { params })
      .toPromise();
  }

  getParams(loadOptions: LoadOptions) {
    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== '';
    }

    let params: HttpParams = new HttpParams();
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params = params.set(i, JSON.stringify(loadOptions[i]));
      }
    });

    return params;
  }
}
