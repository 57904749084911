import { OrderTask, TaskPhoto, ProductBase, TasksGallery, OosChart, WorkTimeChart, OrderTaskStatus, SurveyData, OfficeApprovalConfirmation } from './order-task.model';
import {
    TaskActions,
    SELECTED_TASK,
    ADD,
    ADD_RANGE,
    ADD_MY_TASK,
    ADD_PHOTO,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_TASKS,
    SET_MY_TASKS,
    SET_AVAILABLE_TASK_PHOTOS,
    SET_AVAILABLE_TASKS_GALLERY,
    SET_AVAILABLE_TASK_OOS_PRODUCTS,
    SET_CHART_OOS_PRODUCTS_BY_GROUP,
    SET_CHART_OOS_PRODUCTS_BY_LOCALIZATION,
    SET_CHART_OOS_PRODUCTS_BY_SALESREPRESENTATIVE,
    SET_CHART_WORKTIME_BY_MARKET,
    UPDATE,
    UPDATE_MY_TASK,
    REMOVE,
    REMOVE_MY_TASK,
    START_LOADING,
    STOP_LOADING,
    START_GALLERY_LOADING,
    STOP_GALLERY_LOADING,
    REMOVE_PHOTO,
    SET_SURVEY_DATA,
    SET_ERROR,
    SET_TEMP_CONTAINER_REFRESHED,
    SET_RELOAD_DATA,
    SET_OFFICE_APPROVAL_CONFIRMATION
} from './order-task.actions';

export interface State {
    tasks: OrderTask[];
    myTasks: OrderTask[];
    taskPhotos: TaskPhoto[];
    tasksGallery: TasksGallery[];
    taskProducts: ProductBase[];
    selectedTask: OrderTask;
    isEditing: boolean;
    isLoading: boolean;
    isGalleryLoading: boolean;
    oosProductsByGroup: OosChart[];
    oosProductsByLocalization: OosChart[];
    oosProductsBySalesRepresentative: OosChart[];
    workTimeByMarket: WorkTimeChart[];
    surveyData: SurveyData;
    error: boolean;
    tempContainerRefreshed: boolean;
    reloadData: boolean;
    officeApprovalConfirmation: OfficeApprovalConfirmation;
}

const initialState: State = {
    tasks: [],
    myTasks: [],
    taskPhotos: [],
    tasksGallery: [],
    taskProducts: [],
    selectedTask: null,
    isEditing: false,
    isLoading: false,
    isGalleryLoading: false,
    oosProductsByGroup: [],
    oosProductsByLocalization: [],
    oosProductsBySalesRepresentative: [],
    workTimeByMarket: [],
    surveyData: null,
    error: false,
    tempContainerRefreshed: false,
    reloadData: false,
    officeApprovalConfirmation: null
};

export function orderTaskReducer(state = initialState, action: TaskActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case START_GALLERY_LOADING: {
            return {
                ...state,
                isGalleryLoading: true
            };
        }
        case STOP_GALLERY_LOADING: {
            return {
                ...state,
                isGalleryLoading: false
            };
        }
        case SELECTED_TASK: {
            return {
                ...state,
                selectedTask: action.payload
            };
        }
        case ADD: {
            return {
                ...state,
                tasks: [...state.tasks, action.payload]
            };
        }
        case ADD_RANGE: {
            return {
                ...state,
                tasks: [...state.tasks, ...action.payload]
            };
        }
        case ADD_MY_TASK: {
            return {
                ...state,
                myTasks: [...state.myTasks, action.payload]
            };
        }
        case ADD_PHOTO: {
            return {
                ...state,
                taskPhotos: [...state.taskPhotos, action.payload]
            };
        }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_TASKS: {
            return {
                ...state,
                tasks: action.payload
            };
        }
        case SET_MY_TASKS: {
            return {
                ...state,
                myTasks: action.payload
            };
        }
        case SET_AVAILABLE_TASK_PHOTOS: {
            return {
                ...state,
                taskPhotos: action.payload
            };
        }
        case SET_AVAILABLE_TASKS_GALLERY: {
            return {
                ...state,
                tasksGallery: action.payload
            };
        }
        case SET_AVAILABLE_TASK_OOS_PRODUCTS: {
            return {
                ...state,
                taskProducts: action.payload
            };
        }
        case SET_CHART_OOS_PRODUCTS_BY_GROUP: {
            return {
                ...state,
                oosProductsByGroup: action.payload
            };
        }
        case SET_CHART_OOS_PRODUCTS_BY_LOCALIZATION: {
            return {
                ...state,
                oosProductsByLocalization: action.payload
            };
        }
        case SET_CHART_OOS_PRODUCTS_BY_SALESREPRESENTATIVE: {
            return {
                ...state,
                oosProductsBySalesRepresentative: action.payload
            };
        }
        case SET_CHART_WORKTIME_BY_MARKET: {
            return {
                ...state,
                workTimeByMarket: action.payload
            };
        }
        case UPDATE: {
            const index = state.tasks.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selTask = state.selectedTask;
            if (selTask && selTask.id === action.payload.id) {
                selTask = action.payload;
            }
            const updatedTasks = [
                ...state.tasks.slice(0, index),
                Object.assign({}, state.tasks[index], action.payload),
                ...state.tasks.slice(index + 1)
            ];

            return {
                ...state,
                tasks: updatedTasks,
                selectedTask: selTask
            };
        }
        case UPDATE_MY_TASK: {
            const index = state.myTasks.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }

            // if (action.payload.status !== OrderTaskStatus.New) {
            //   return {
            //     ...state,
            //     myTasks: state.myTasks.filter(c => c.id !== action.payload.id)
            //   };
            // }

            let selTask = state.selectedTask;
            if (selTask && selTask.id === action.payload.id) {
                selTask = action.payload;
            }
            const updatedTasks = [
                ...state.myTasks.slice(0, index),
                Object.assign({}, state.myTasks[index], action.payload),
                ...state.myTasks.slice(index + 1)
            ];

            return {
                ...state,
                myTasks: updatedTasks,
                selectedTask: selTask
            };
        }
        case REMOVE: {
            return {
                ...state,
                tasks: state.tasks.filter(c => c.id !== action.payload)
            };
        }
        case REMOVE_MY_TASK: {
            return {
                ...state,
                myTasks: state.myTasks.filter(c => c.id !== action.payload)
            };
        }
        case REMOVE_PHOTO: {
            return {
                ...state,
                taskPhotos: state.taskPhotos.filter(c => c.attachemntId !== action.payload)
            };
        }
        case SET_SURVEY_DATA: {
            return {
                ...state,
                surveyData: action.payload
            };
        }
        case SET_ERROR: {
            return {
                ...state,
                error: action.payload
            };
        }
        case SET_TEMP_CONTAINER_REFRESHED: {
            return {
                ...state,
                tempContainerRefreshed: action.payload
            };
        }
        case SET_RELOAD_DATA: {
            return {
                ...state,
                reloadData: action.payload
            };
        }
        case SET_OFFICE_APPROVAL_CONFIRMATION: {
            return {
                ...state,
                officeApprovalConfirmation: action.payload
            };
        }        
        default: {
            return state;
        }
    }
}

export const tasks = (state: State) => state.tasks;
export const myTasks = (state: State) => state.myTasks;
export const taskPhotos = (state: State) => state.taskPhotos;
export const tasksGallery = (state: State) => state.tasksGallery;
export const taskProducts = (state: State) => state.taskProducts;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const isGalleryLoading = (state: State) => state.isGalleryLoading;
export const selectedTask = (state: State) => state.selectedTask;
export const oosProductsByGroup = (state: State) => state.oosProductsByGroup;
export const oosProductsByLocalization = (state: State) => state.oosProductsByLocalization;
export const oosProductsBySalesRepresentative = (state: State) => state.oosProductsBySalesRepresentative;
export const workTimeByMarket = (state: State) => state.workTimeByMarket;
export const surveyData = (state: State) => state.surveyData;
export const error = (state: State) => state.error;
export const tempContainerRefreshed = (state: State) => state.tempContainerRefreshed;
export const reloadData = (state: State) => state.reloadData;
export const officeApprovalConfirmation = (state: State) => state.officeApprovalConfirmation;