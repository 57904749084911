import { Localization, LocalizationListItem, Market } from './localization.model';
import {
    LocalizationActions,
    SELECTED_LOCALIZATION,
    SELECTED_MARKET,
    ADD_LOCALIZATION,
    ADD_MARKET,
    START_EDITING,
    STOP_EDITING,
    SET_AVAILABLE_LOCALIZATIONS,
    SET_AVAILABLE_PROJECT_LOCALIZATIONS,
    SET_AVAILABLE_MARKETS,
    UPDATE_LOCALIZATION,
    UPDATE_MARKET,
    REMOVE_LOCALIZATION,
    REMOVE_MARKET,
    START_LOADING,
    STOP_LOADING,
    SET_SEARCH_LOCALIZATION_LIST,
    START_LOADING_MARKETS,
    STOP_LOADING_MARKETS,
    SET_RELOAD_DATA_GRID,
} from './localization.actions';

export interface State {
    localizations: Localization[];
    projectLocalizations: Localization[];
    markets: Market[];
    selectedLocalization: Localization;
    selectedMarket: Market;
    isEditing: boolean;
    isLoading: boolean;
    isLoadingMarkets: boolean;
    searchedLocalizationList: LocalizationListItem[];
    reloadDataGrid: boolean;
}

const initialState: State = {
    localizations: [],
    projectLocalizations: [],
    markets: [],
    selectedLocalization: null,
    selectedMarket: null,
    isEditing: false,
    isLoading: false,
    isLoadingMarkets: false,
    searchedLocalizationList: [],
    reloadDataGrid: false,
};

export function localizationReducer(state = initialState, action: LocalizationActions) {
    switch (action.type) {
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case START_LOADING_MARKETS: {
            return {
                ...state,
                isLoadingMarkets: true
            };
        }
        case STOP_LOADING_MARKETS: {
            return {
                ...state,
                isLoadingMarkets: false
            };
        }
        case SELECTED_LOCALIZATION: {
            return {
                ...state,
                selectedLocalization: action.payload
            };
        }
        case SELECTED_MARKET: {
            return {
                ...state,
                selectedMarket: action.payload
            };
        }
        case ADD_LOCALIZATION: {
            return {
                ...state,
                localizations: [...state.localizations, action.payload]
            };
        }
        case ADD_MARKET: {
            return {
                ...state,
                markets: [...state.markets, action.payload]
            };
        }
        case START_EDITING:
            return {
                ...state,
                isEditing: true
            };
        case STOP_EDITING:
            return {
                ...state,
                isEditing: false
            };
        case SET_AVAILABLE_LOCALIZATIONS: {
            return {
                ...state,
                localizations: action.payload
            };
        }
        case SET_AVAILABLE_PROJECT_LOCALIZATIONS: {
            return {
                ...state,
                projectLocalizations: action.payload
            };
        }
        case SET_AVAILABLE_MARKETS: {
            return {
                ...state,
                markets: action.payload
            };
        }
        case UPDATE_LOCALIZATION: {
            const index = state.localizations.map(d => d.id).indexOf(action.payload.id);
            const projectLocalizationIndex = state.projectLocalizations.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selLocalization = state.selectedLocalization;
            if (selLocalization && selLocalization.id === action.payload.id) {
                selLocalization = action.payload;
            }
            const updatedLocalizations = [
                ...state.localizations.slice(0, index),
                Object.assign({}, state.localizations[index], action.payload),
                ...state.localizations.slice(index + 1)
            ];
            let updatedProjectLocalizations;
            if (projectLocalizationIndex !== -1) {
                updatedProjectLocalizations = [
                    ...state.projectLocalizations.slice(0, index),
                    Object.assign({}, state.projectLocalizations[index], action.payload),
                    ...state.projectLocalizations.slice(index + 1)
                ];
            }


            return {
                ...state,
                localizations: updatedLocalizations,
                projectLocalizations: (projectLocalizationIndex !== -1) ? updatedProjectLocalizations : state.projectLocalizations,
                selectedLocalization: selLocalization
            };
        }
        case UPDATE_MARKET: {
            const index = state.markets.map(d => d.id).indexOf(action.payload.id);
            if (index === -1) {
                return state;
            }
            let selMarket = state.selectedMarket;
            if (selMarket && selMarket.id === action.payload.id) {
                selMarket = action.payload;
            }
            const updatedMarkets = [
                ...state.markets.slice(0, index),
                Object.assign({}, state.markets[index], action.payload),
                ...state.markets.slice(index + 1)
            ];

            return {
                ...state,
                markets: updatedMarkets,
                selectedMarket: selMarket
            };
        }
        case REMOVE_LOCALIZATION: {
            return {
                ...state,
                localizations: state.localizations.filter(c => c.id !== action.payload),
            };
        }
        case REMOVE_MARKET: {
            return {
                ...state,
                markets: state.markets.filter(c => c.id !== action.payload),
            };
        }
        case SET_SEARCH_LOCALIZATION_LIST: {
            return {
                ...state,
                searchedLocalizationList: action.payload,
            };
        }
        case SET_RELOAD_DATA_GRID: {
            return {
                ...state,
                reloadDataGrid: action.payload
            };
        }

        default: {
            return state;
        }
    }
}

export const localizations = (state: State) => state.localizations;
export const projectLocalizations = (state: State) => state.projectLocalizations;
export const markets = (state: State) => state.markets;
export const isEditing = (state: State) => state.isEditing;
export const isLoading = (state: State) => state.isLoading;
export const isLoadingMarkets = (state: State) => state.isLoadingMarkets;
export const selectedLocalization = (state: State) => state.selectedLocalization;
export const selectedMarket = (state: State) => state.selectedMarket;
export const searchedLocalizationList = (state: State) => state.searchedLocalizationList;
export const reloadDataGrid = (state: State) => state.reloadDataGrid;
