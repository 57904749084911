import { FooterComponent } from './footer/footer.component';
import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material.module';
import { EDictionaryPipe } from './pipes/eDictionary.pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { YesNoDialogComponent } from './yes-no-dialog/yes-no-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFilterComponent } from './mat-filter.component';
import { AvatarModule } from 'ngx-avatar';
import { PasswordChangeFormComponent } from './password-change-form/password-change-form.component';
import { PasswordChangeFormDialogComponent } from './password-change-form/password-change-form-dialog.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '../jwt.interceptor';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DXModule } from './dx.module';
import { LY_THEME, LyThemeModule } from '@alyle/ui';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { LyResizingCroppingImageModule } from '@alyle/ui/resizing-cropping-images';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CompanyCreateUserComponent } from '../administration/companies/company-user/company-create-user/company-create-user.component';
import { OnlineUsersChartComponent } from '../components/online-users-chart/online-users-chart.component';
import { MatTabScrollFixDirective } from '../directives/mat-tab-scroll-fix.directive';
import { CreateValueComponent } from './hr-component/create-value/create-value.component';
import { SelectUserComponent } from './select-user/select-user.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { ContractorDetailsComponent } from '../contractor/contractor-details/contractor-details.component';
import { ServiceDetailsComponent } from '../service/service-details/service-details.component';
import { FileSizePipe } from '../pipes/fileSizePipe.pipe';
import { ProductListComponent } from '../contractor/product/product-list/product-list.component';
import { LocalizationSelectComponent } from '../localization/localization-select/localization-select.component';
import { ProductSelectComponent } from '../contractor/product/product-select/product-select.component';
import { DxPieChartModule, DxSelectBoxModule, DxChartModule, DxDateBoxModule, DxHtmlEditorModule, DxCheckBoxModule, DxSchedulerModule } from 'devextreme-angular';
import { OosProductChartByGroupComponent } from '../components/oos-product-chart/oos-product-chart-by-group/oos-product-chart-by-group.component';
import { OosProductChartBySalesRepresentativeComponent } from '../components/oos-product-chart/oos-product-chart-by-sales-representative/oos-product-chart-by-sales-representative.component';
import { OosProductChartByLocalizationComponent } from '../components/oos-product-chart/oos-product-chart-by-localization/oos-product-chart-by-localization.component';
import { OnlineUsersChartDxComponent } from '../components/online-users-chart-dx/online-users-chart-dx.component';
import { WorkTimeChartByLocalizationComponent } from '../components/work-time-chart/work-time-chart-by-localization/work-time-chart-by-localization.component';
import dxChart from 'devextreme/viz/chart';
import { OosConfirmationDialogComponent } from './oos-confirmation-dialog/oos-confirmation-dialog.component';
import { OrderTaskDetailsComponent } from '../order-task/order-task-details/order-task-details.component';
import { MyTasksListComponent } from '../order-task/mytasks-list/mytasks-list.component';
import { GalleryComponent } from '../gallery/gallery.component';
import { NgxGalleryModule } from 'ngx-gallery';
//import { SurveyDesignerComponent } from '../survey/survey-designer/survey-designer';
import { SurveyCreatorComponent } from '../survey/survey-component/survey.creator.component';
import { SurveyPage } from '../survey/survey-page/survey.page';
import { SurveyComponent } from '../survey/survey-component/survey.component';
import { SelectMerchDialogComponent } from './select-merch-dialog/select-merch-dialog.component';
import { TwoDigitDecimaNumberDirective } from '../directives/two-digit-decima-number.directive';
import { PositiveTwoDigitDecimalNumber } from '../directives/positive-two-digit-decima-number.directive';
import { CentralPromotionDetailsComponent } from '../central-promotion/central-promotion-details/central-promotion-details.component';
import { ReportConfigurationComponent } from '../order/report-configuration/report-configuration.component';
import { TaskGenerationPlanComponent } from '../order-task/task-generation-plan/task-generation-plan.component';
import { SurveyResultComponent } from '../order-task/survey-result/survey-result.component';
import { PeselValidatorDirective } from '../directives/pesel-validator.directive';
import { PhoneNumberValidatorDirective } from '../directives/phone-number-validator.directive';
import { IBAN, IBANValidatorDirective } from '../directives/iban-validator.directive';
import { UserProjectConfigurationComponent } from '../employee-form/user-project-configuration/user-project-configuration.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { CalendarComponent } from '../calendars/calendar.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { StartEndDatePickerComponent } from './start-end-date-picker/start-end-date-picker.component';
import { UserSearchBarComponent } from './user-search-bar/user-search-bar.component';
import { UserSearchBarMultipleComponent } from './user-search-bar-multiple/user-search-bar-multiple.component';
import { UserSearchBarWithRolesComponent } from './user-search-bar-with-roles/user-search-bar-with-roles.component';
import { UserSearchBarByGroupComponent } from './user-search-bar-by-group/user-search-bar-by-group.component';
import { LocalizationSearchBarComponent } from './localization-search-bar/localization-search-bar.component';
import { DropdownSelectionComponent } from './dropdown-selection/dropdown-selection.component';
import { StartEndDatePickerDialogComponent } from './start-end-date-picker-dialog/start-end-date-picker-dialog.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    // suppressScrollX: true,
    wheelSpeed: 2
};

@NgModule({
    entryComponents: [
        YesNoDialogComponent,
        SelectMerchDialogComponent,
        OosConfirmationDialogComponent,
        PasswordChangeFormDialogComponent,
        ImageEditorComponent,
        UserDetailsComponent,
        CreateValueComponent,
        CompanyCreateUserComponent,
        ServiceDetailsComponent,
        MessageDialogComponent,
        StartEndDatePickerDialogComponent,
    ],
    declarations: [
        PasswordChangeFormDialogComponent,
        YesNoDialogComponent,
        SelectMerchDialogComponent,
        OosConfirmationDialogComponent,
        FooterComponent,
        MatFilterComponent,
        EDictionaryPipe,
        FileSizePipe,
        PasswordChangeFormComponent,
        ImageEditorComponent,
        UserDetailsComponent,
        CompanyCreateUserComponent,
        CreateValueComponent,
        OnlineUsersChartComponent,
        OosProductChartByGroupComponent,
        OosProductChartBySalesRepresentativeComponent,
        OosProductChartByLocalizationComponent,
        WorkTimeChartByLocalizationComponent,
        OnlineUsersChartDxComponent,
        MatTabScrollFixDirective,
        SelectUserComponent,
        ContractorDetailsComponent,
        ServiceDetailsComponent,
        ProductListComponent,
        LocalizationSelectComponent,
        ProductSelectComponent,
        OrderTaskDetailsComponent,
        NotificationsComponent,
        CalendarComponent,
        MyTasksListComponent,
        TaskGenerationPlanComponent,
        SurveyResultComponent,
        GalleryComponent,
        SurveyPage,
        //SurveyDesignerComponent,
        SurveyCreatorComponent,
        SurveyComponent,
        TwoDigitDecimaNumberDirective,
        PositiveTwoDigitDecimalNumber,
        PeselValidatorDirective,
        PhoneNumberValidatorDirective,
        IBANValidatorDirective,
        CentralPromotionDetailsComponent,
        ReportConfigurationComponent,
        UserProjectConfigurationComponent,
        LoadingSpinnerComponent,
        MessageDialogComponent,
        StartEndDatePickerComponent,
        UserSearchBarComponent,
        UserSearchBarMultipleComponent,
        UserSearchBarWithRolesComponent,
        UserSearchBarByGroupComponent,
        LocalizationSearchBarComponent,
        DropdownSelectionComponent,
        StartEndDatePickerDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MaterialModule,
        DXModule,
        NgxMaterialTimepickerModule,
        AvatarModule,
        NgxChartsModule,
        DxPieChartModule,
        DxChartModule,
        NgxChartsModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        // SocialLoginModule
        LyThemeModule.setTheme('minima-light'),
        CommonModule,
        FormsModule,
        LyResizingCroppingImageModule,
        LyButtonModule,
        LyIconModule,
        PerfectScrollbarModule,
        MaterialFileInputModule,
        NgxGalleryModule,
        DxHtmlEditorModule,
        DxSchedulerModule,
        DxCheckBoxModule,
    ],
    exports: [
        MatTabScrollFixDirective,
        PerfectScrollbarModule,
        UserDetailsComponent,
        ImageEditorComponent,
        SelectUserComponent,
        MatFilterComponent,
        SelectMerchDialogComponent,
        FooterComponent,
        PasswordChangeFormComponent,
        EDictionaryPipe,
        FileSizePipe,
        FlexLayoutModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        DXModule,
        AvatarModule,
        NgxMaterialTimepickerModule,
        NgxChartsModule,
        DxPieChartModule,
        DxChartModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        OnlineUsersChartComponent,
        OosProductChartByGroupComponent,
        OosProductChartBySalesRepresentativeComponent,
        OosProductChartByLocalizationComponent,
        WorkTimeChartByLocalizationComponent,
        OnlineUsersChartDxComponent,
        MaterialFileInputModule,
        ContractorDetailsComponent,
        ServiceDetailsComponent,
        ProductListComponent,
        LocalizationSelectComponent,
        ProductSelectComponent,
        OrderTaskDetailsComponent,
        MyTasksListComponent,
        TaskGenerationPlanComponent,
        SurveyResultComponent,
        GalleryComponent,
        SurveyPage,
        NgxGalleryModule,
        DxHtmlEditorModule,
        DxSchedulerModule,
        DxCheckBoxModule,
        //SurveyDesignerComponent,
        SurveyCreatorComponent,
        SurveyComponent,
        TwoDigitDecimaNumberDirective,
        PositiveTwoDigitDecimalNumber,
        PeselValidatorDirective,
        PhoneNumberValidatorDirective,
        IBANValidatorDirective,
        CentralPromotionDetailsComponent,
        ReportConfigurationComponent,
        UserProjectConfigurationComponent,
        LoadingSpinnerComponent,
        StartEndDatePickerComponent,
        UserSearchBarComponent,
        UserSearchBarMultipleComponent,
        UserSearchBarWithRolesComponent,
        UserSearchBarByGroupComponent,
        LocalizationSearchBarComponent,
        DropdownSelectionComponent,
        StartEndDatePickerDialogComponent,
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: LY_THEME,
            useClass: MinimaLight
        },
    ]
})
export class SharedModule {
}
