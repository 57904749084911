import { AuditActions, SET_OBJECT_TYPES, SET_SELECTED_AUDIT, START_LOADING, STOP_LOADING } from "./audit.actions";
import { Audit, AuditObjectType } from "./audit.model";

export interface State {
    selectedAudit: Audit;
    isLoading: boolean;
    objectTypes: AuditObjectType[];
}

const initialState: State = {
    selectedAudit: null,
    isLoading: false,
    objectTypes: null,
}
export function auditReducer(state = initialState, action: AuditActions) {
    switch (action.type) {
        case SET_OBJECT_TYPES: {
            return {
                ...state,
                objectTypes: action.payload
            };
        }
        case SET_SELECTED_AUDIT: {
            return {
                ...state,
                selectedAudit: action.payload
            };
        }
        case START_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case STOP_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        default: {
            return state;
        }
    }
}

export const isLoading = (state: State) => state.isLoading;
export const selectedAudit = (state: State) => state.selectedAudit;
export const objectTypes = (state: State) => state.objectTypes;